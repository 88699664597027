<template>
  <div v-if="status" id="user-list">
    <!-- app drawer -->
    <customer-list v-if="userData.profile === '30' && $vuetify.breakpoint.xsOnly" :style-theme="false" />

    <!-- list filters -->
    <v-card rounded="xl" :color="$vuetify.breakpoint.xsOnly ? 'white' : 'thead-color'" :disabled="loading">
      <v-card-title class="text-h6" :class="$vuetify.breakpoint.xsOnly ? 'thead-color' : 'form-color'">
        <v-icon left>
          {{ icons.mdiPoll }}
        </v-icon>
        Satış Raporu
      </v-card-title>

      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menuStartDate"
            v-model="menuStartDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                class="rounded-0"
                label="Başlangıç Tarihi"
                rounded
                readonly
                filled
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDateFormat" no-title @input="menuStartDate = false"></v-date-picker>
          </v-menu>
          <v-divider v-if="$vuetify.breakpoint.xsOnly" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menuEndDate"
            v-model="menuEndDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                class="rounded-0"
                label="Bitiş Tarihi"
                rounded
                readonly
                filled
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endDateFormat" no-title @input="menuEndDate = false"></v-date-picker>
          </v-menu>
          <v-divider v-if="$vuetify.breakpoint.xsOnly" />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-sheet color="primary" height="20">
            <v-sheet
              :color="$vuetify.breakpoint.xsOnly ? 'thead-color' : 'form-color'"
              rounded="b-xl"
              height="20"
            ></v-sheet>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <v-btn
            block
            color="primary"
            class="rounded-0 rounded-b-xl text-caption font-weight-bold"
            x-large
            @click.stop="submitForm"
          >
            <v-icon left>
              {{ icons.mdiMagnify }}
            </v-icon>
            Ara
          </v-btn>
        </v-col>
      </v-row>

      <!-- table -->

      <v-data-table :headers="tableColumns" :sort-desc="true" :search="search" :loading="loading" :items="tableItems">
        <template v-slot:item.SiparisAdeti="{ item }">
          <div class="">{{ item.SiparisAdeti }} Adet</div>
        </template>
      </v-data-table>
    </v-card>
  </div>
  <div v-else>
    <customer-list v-if="userData.profile === '30' && $vuetify.breakpoint.xsOnly" />
    <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
      Lütfen yukarıdan bir müşteri seçin
    </v-alert>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import axios from '@axios'
import {
  mdiAccountOutline,
  mdiCircle,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiMagnify,
  mdiPoll,
} from '@mdi/js'

export default {
  components: {
    CustomerList,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      status: false,
      tableItems: [],
      tableColumns: [
        { text: 'Marka', value: 'BrandDescription', width: '200' },
        { text: 'Ürün', value: 'ItemDescription', align: 'stat min-width-400' },
        { text: 'Miktar', value: 'SiparisAdeti', width: '200' },
        { text: 'Ay', value: 'Months', width: '100' },
        { text: 'Yıl', value: 'Year', width: '100' },
      ],
      search: '',
      loading: false,

      startDateFormat: new Date(Date.now() + new Date().getTimezoneOffset() * 60000 * 10 * 30)
        .toISOString()
        .substr(0, 10),
      startDate: this.formatDateStart(
        new Date(Date.now() + new Date().getTimezoneOffset() * 60000 * 10 * 30).toISOString().substr(0, 10),
      ),
      endDateFormat: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      endDate: this.formatDateEnd(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ),
      menuStartDate: false,
      menuEndDate: false,
      icons: {
        mdiPoll,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiCircle,
        mdiMagnify,
        mdiAccountOutline,
      },
    }
  },

  watch: {
    startDateFormat(val) {
      this.startDate = this.formatDateStart(this.startDateFormat)
    },
    endDateFormat(val) {
      this.endDate = this.formatDateEnd(this.endDateFormat)
    },
    '$store.state.plasiyerCustomer': function (newValue) {
      if (newValue) {
        this.status = true
        this.submitForm()
      }
    },
  },
  mounted() {
    this.submitForm()
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData.profile === '30' && store.state.plasiyerCustomer === null) {
      this.status = false
    } else {
      this.status = true
    }
  },

  methods: {
    formatDateStart(startDateFormat) {
      if (!startDateFormat) return null

      const [year, month, day] = startDateFormat.split('-')

      return `${day}/${month}/${year}`
    },
    formatDateEnd(endDateFormat) {
      if (!endDateFormat) return null

      const [year, month, day] = endDateFormat.split('-')

      return `${day}/${month}/${year}`
    },

    async submitForm() {
      this.loading = true
      const params = new URLSearchParams()
      params.append('method', 'getStockPurchaseReport')
      params.append('start_date', this.startDateFormat)
      params.append('end_date', this.endDateFormat)
      axios
        .post('', params)
        .then(response => {
          this.tableItems = response.data.detail
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
.v-data-table-header-mobile {
  display: none;
}
.theme--light.v-data-table th {
  border-top: none !important;
}
.min-width-400 {
  min-width: 400px;
}
</style>
